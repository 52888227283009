module.exports = {
  BASE_URL: "https://hethongvib-cdn.laocredit.cash/api/v1",
  SOCKET_SERVER_URL: "https://hethongvib-cdn.laocredit.cash",
  // BASE_URL: 'http://192.168.35.109:8082/api/v1',
  // SOCKET_SERVER_URL: 'http://192.168.35.109:8082'

  MONEY_LOAN: [
    50000000, 60000000, 80000000, 100000000, 200000000,
    300000000, 400000000, 500000000, 600000000, 800000000, 1000000000, 2000000000
  ],
  MONTH_LOAN: [6, 12, 18, 24, 36, 48],
  MIN_LOAN_AMOUNT: 10000000,
  MAX_LOAN_AMOUNT: 500000000,
  LOAN_RATE_PER_MONTH: 0.9,
  SYSTEM_CURRENCY: "VNĐ",
};
