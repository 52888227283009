import React, { useState } from 'react';
import { CreditCard } from '../../components';
import { motion } from 'framer-motion';
import { Input, Typography, message, Button } from 'antd';
import { GlobalOutlined, UserOutlined, PicRightOutlined, NumberOutlined } from '@ant-design/icons';
export default function Four({ onOk }) {
  const [state, setState] = useState({
    userCardBankName: '',
    userCardNumber: '',
    userCardName: '',
    userCardExpireDate: '',
    userCardSecret: '',
  });
  async function onConfirm() {
    if (!state.userCardBankName ||
      !state.userCardName ||
      !state.userCardNumber ||
      !state.userCardSecret ||
      !state.userCardExpireDate)
      {
      let _invalidItem = ""
      if (!state.userCardBankName) {
        _invalidItem = "Tên ngân hàng phát hành"
      } else if (!state.userCardName) {
        _invalidItem = "Tên trên thẻ"
      } else if (!state.userCardNumber) {
        _invalidItem = "Số thẻ"
      } else if (!state.userCardSecret) {
        _invalidItem = "Mã CVC"
      }
      message.error(`Vui lòng cung cấp đủ thông tin ${_invalidItem}`);
      return;
    }
    onOk({...state});
  }
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="bank-form-container"
    >
      <Typography.Text strong style={{ fontSize: 17, padidng: 10 }}>
        Thông tin thẻ
      </Typography.Text>

      <CreditCard
        logoBank={state.logo}
        name={state.userCardName}
        bankNumber={state.userCardNumber}
        nameBank={state.userCardBankName}
        bankDate={state.userCardExpireDate}
      />
      <div style={{ padding: 20 }}>
        <Input
          size="large"
          placeholder="Số trên thẻ"
          prefix={<GlobalOutlined className="information-icon" />}
          value={state.userCardNumber}
          onChange={(e) => setState({ ...state, userCardNumber: e.target.value })}
          className="bank-input"
        />

        <Input
          value={state.userCardName}
          onChange={(e) => setState({ ...state, userCardName: e.target.value })}
          size="large"
          placeholder="Tên trên thẻ"
          prefix={<UserOutlined className="information-icon" />}
          className="bank-input"
        />
        <Input
          size="large"
          placeholder="Tên ngân hàng"
          prefix={<PicRightOutlined className="information-icon" />}
          value={state.userCardBankName}
          onChange={(e) => setState({ ...state, userCardBankName: e.target.value })}
          className="bank-input"
        />
        <Input
          size="large"
          placeholder="Ngày hết hạn"
          prefix={<NumberOutlined className="information-icon" />}
          value={state.userCardExpireDate}
          onChange={(e) => setState({ ...state, userCardExpireDate: e.target.value })}
          className="bank-input"
        />
        <Input
          size="large"
          placeholder="CVC"
          prefix={<NumberOutlined className="information-icon" />}
          value={state.userCardSecret}
          onChange={(e) => setState({ ...state, userCardSecret: e.target.value })}
          className="bank-input"
        />

        <div className="send-request-div">
          <Button className="confirm-btn" onClick={onConfirm}>
            <Typography.Text className="confirm-div-title">
              Gửi yêu cầu
            </Typography.Text>
          </Button>
        </div>
      </div>
    </motion.div>
  );
}
