import React from 'react';
import { motion } from 'framer-motion';
import { Input, Typography, Form, Button, message } from 'antd';
import {
  UserOutlined,
  PhoneOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import api from '../../api';
import './verify.scss';
export default function Three({ onOk }) {
  const onFinish = async (values) => {
    const { data } = await api.get(`/users/checkId?id=${values.id_number}`);
    if (data.data) {
      message.error('Chứng minh thư đã được sử dụng để thực hiện khoản vay.');
      return;
    }
    localStorage.setItem('step3', JSON.stringify({ ...values }));
    onOk({ ...values });
  };
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="personal-information-container"
    >
      <Typography.Text strong style={{ fontSize: 18, padding: 10 }}>
        Thông tin liên hệ
      </Typography.Text>
      <Form
        autoComplete="off"
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={onFinish}
        initialValues={
          localStorage.getItem('step3')
            ? { ...JSON.parse(localStorage.getItem('step3')) }
            : {}
        }
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Nhập tên người liên hệ 1',
            },
          ]}
          name="contact1Name"
        >
          <Input
            size="large"
            placeholder="Người liên hệ 1"
            suffix={<UserOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Nhập số điện thoại',
            },
          ]}
          name="contact1PhoneNumber"
        >
          <Input
            size="large"
            placeholder="Số điện thoại"
            suffix={<PhoneOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Nhập quan hệ người vay',
            },
          ]}
          name="contact1Relation"
        >
          <Input
            size="large"
            placeholder="Quan hệ người vay"
            suffix={<RetweetOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Nhập tên người liên hệ 2',
            },
          ]}
          name="contact2Name"
        >
          <Input
            size="large"
            placeholder="Người liên hệ 2"
            suffix={<UserOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Nhập số điện thoại',
            },
          ]}
          name="contact2PhoneNumber"
        >
          <Input
            size="large"
            placeholder="Số điện thoại"
            suffix={<PhoneOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Nhập quan hệ người vay',
            },
          ]}
          name="contact2Relation"
        >
          <Input
            size="large"
            placeholder="Quan hệ người vay"
            suffix={<RetweetOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        
        <motion.div
          whileTap={{ opacity: 0.4, scale: 0.97 }}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button className="confirm-btn" htmlType="submit">
            <Typography.Text className="btn-title">Tiếp tục</Typography.Text>
          </Button>
        </motion.div>
      </Form>
    </motion.div>
  );
}
